.contactcontainer {
  background-color:  #040404;
  }

 
  .contactsbody{
    background-color:  #040404;
    color:rgba(255, 255, 255, 0.801);
    display: flex;
    flex-direction: column;
    overflow-y: visible;
    /* justify-content: flex-start;
    align-items: flex-start; */
    /* gap: 200px; */
    margin-top:100px;
    /* position: absolute;
    left:0; */
  }

  .personalDetails{
    position: absolute;
    left:5%;
    top: 25%;
    color:rgba(255, 255, 255, 0.801);
  }

.link{
  color: #0076B2;
}
 

.formbutton{
  margin:5px;
}

.findmesection{
  position: absolute;
  bottom: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.findmerow{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
}

.findme{
  text-align: center;
  width: 100%;
}



.social-media{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
  margin: 0 auto;
}

.impressum{
  margin: 0 auto;
  position: absolute;
  bottom: 1%;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
}

.impressum a{
  color: #0076B2;
}

.impressum a{
  display: block;
  text-align: center;
 
}