/* body{
   
    margin-top:100px;
} */

.funfactsbody{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}


.modalcard{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.funfactscol{
    display: flex;
    justify-content: center;
    align-items: center;
}