/* 
      .container {
        position: relative;
        height: 100vh;
        max-width: 100vw;
        margin-top: 100px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        background-color:#040404;
        margin-bottom: 0;
        
      }
    
    
      .container h1{
        font-size: 64px;
        color:rgba(255, 255, 255, 0.801);
        
      }

      .container.text-complete{
        height:20vh;
      
      }

      .profilePicture{
        opacity: 0;
        transition:  transform 1s ease-in-out;
        transition: border-radius 0.5s ease;
      }

    
    
     
      .profilePicture.circular {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        margin-top: 100px;
      }
    
      .profilePicture.circular img {
        width: 380px;
        height: 380px;
        position: absolute;
        border-radius: 50%;
        transition: all 2s ease;
        
      }

      .textShifted {
        transform: translate(-35vw, -3vh);
        margin-left: 20px;
        transition: all 1s ease;
      }
      
      .profilePicture.sidebar {
        transform: translateX(-35vw);
        transition: all 1s ease;
        margin-left: 4rem; 
        margin-top: 1rem;
      }
    
      .profilePicture.sidebar img {
        border-radius: 8px;
        transform: translateX(-8%);
        height: 60vh;
        width: 25vw;
        margin-top: 200px;
      }

      .introDiv {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: center;
        position: relative;
        align-items: center;
        opacity: 0;
        transition: opacity 0.5s ease;
        color:rgba(255, 255, 255, 0.801);
        margin-left: 40%;
        width:50vw;
      }

     
      .fade-in-0{
        text-align: center;
        animation: fade-in 0.1s ease  forwards;
      }

      .fade-in-1{
        text-align: center;
        animation: fade-in 1s ease  forwards;
      }

      .fade-in-2{
        text-align: center;
        animation: fade-in 2s ease  forwards;
      }

      .fade-in-3{
        text-align: center;
        animation: fade-in 3s ease  forwards;
      }

      @keyframes fade-in {
        from{
          opacity:0;
        }
        to{
          opacity:1;
        }
      }

       

        @media (max-width: 1200px) and (min-width: 370px) {
          .aboutMe {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        
          .textShifted {
           
            transform: translate(0vw, 0vh);
            position: absolute;
            margin: 0 auto;
          }
        
          .container h1 {
            font-size: 48px;
            margin: 0 auto;
          }
        
          .profilePicture {
            
            height: 20vh;
            margin: 0 auto;
          }
        
          .profilePicture.circular img {
            width: 320px;
            height: 320px;
            margin: 0 auto;
          }
        
          .profilePicture.sidebar {
            transform: translate(0vw, 0vh);
            transition: all 1s ease;
            width: 25vw;
            height: 50vh;
            margin:0 auto;          
          }
        
          .profilePicture.sidebar img {
            border-radius: 8px;
            transform: translateX(0%);
            margin: 0 auto;
          }
        
          .introDiv {
            width:50vw;
            height:90vh;
            flex-direction: column;
            margin: 0 auto;
            margin-top: 40px;
          }

          .skillsDiv{
            flex-direction: row;
            
            height:10vh;
          }
        }

.skillsDiv{
  color: rgb(152, 152, 237);
  margin-top: 20px;
} */






.aboutMe{
  background-image: url('./images/picpro.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:left;
  height:95vh;
  width:100vw;
  color:#929191;
  
}

.content{
  width:50vw;
  padding:1rem;
  position: absolute;
  top:42%;
  left:45%;
}

.para,
.icon,
.caption,
.arrow ,
.journey{
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}



.image-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px -30px;
  width: 38vw;
  
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px; 
}

.image-container:nth-child(3) {
  margin-left: 50px;
}

.icon {
  /* opacity: 1; */
  width: 3.5rem; 
  height: 3.5rem; 
  /* box-shadow: 0 4px 18px rgba(206, 206, 206, 0.2); */

}

.caption {
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
}

.arrow {
  position: relative;
  width: 0;
  height: 0;
}

.arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(224deg);
  width: 12px;
  height: 12px;
  border-left: 2px solid #CDCDCD;
  border-bottom: 2px solid #CDCDCD;
}

.secondPage{
  background-image: url('./images/page2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width:100vw;
  min-height: 100vh;
  margin-top: 0;
  position: relative;
  padding-bottom: 5rem;
}


.interest {
  position: relative; 
  width: 50vw;
  margin: 0 auto;
  color: #929191;
  padding-top: 2rem; 
  padding-bottom: 3rem; 
  min-height: 100vh;
}


.buttonDiv{
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
}

.skills{
  margin-top: 20vh;
  /* height: 70vh; */
  width:100vw;
  color:#929191;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* gap: 4rem; */

  padding: 1rem; /* Add padding to the container */
  
}

.skillContainer{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin:0;  
}

.skillCol{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillUl{
  width:600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}



.skillUl li{
  list-style: none;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  text-align: center;
  position: relative;
  border-radius: 5px;
  background-color: #000;
}

.liStyle::before{
    content: '';
    background: linear-gradient(60deg, #929191, #4682B4,  #B0C4DE, blue, purple);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 30s linear infinite;
    opacity: 0.5;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}





/* .skillButton{
  position: absolute;
  left: 50%;
  transform: translateX(-50%); 
  margin-top:6rem;
} */

.scrollButton{
  margin-top:3rem;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #929191;
  background: #040404;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(206, 206, 206, 0.683);
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}


span{
font-weight:bolder;
/* color: #83CBFF; */
/* color: #baddf6; */
/* color: #002bff; */
/* color: #002bff; */
color: #0D6EFD;
}



@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

@media (max-width: 768px) {
  

  .aboutMe {
    background-size: contain;
    height: 100vh; 
    background-position: center top; 
    margin-top: -50px;
    
  }

  .content {
    width: 90vw; 
    position: relative;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    font-size: 0.8rem;
  }

  .content h3 {
    text-align: center;
  }

  
 
  .body{
    font-size: 0.7rem;
  }
  
  .image-group { 
    width: 30vw; 
    margin-left:2rem;
  }

  .icon {
    width: 2rem; 
    height: 2rem; 
  }

  .image-container {
    margin: 0 12px; 
  }

  .caption {
    white-space: wrap;
  }

  .skillButton{
    padding:4px;
  }

 

  .para{
    width: 50vw;
  }

  .arrow::before {
    width: 8px;
    height: 8px;
  }

  
  
  .scrollButton{
    margin:0rem;
    margin-top: 1.5rem;
    left: 50%;
    transform: translate(-50%);
  }


  .interest{
    width: 80vw;
  }

  .image-container:nth-child(3) {
    margin-left: 20px;
  }

  .skillUl{
    width:300px;
    gap:1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .skillUl li{
    padding: 3px;
  }

  .image-container {
    margin-right: 10px;
  }

  .image-group {
   
    margin: 30px 40px;
    width: 28vw;
    
  }

  .skills{
    margin-top: 6vh;
    
  }

}

@media (min-width: 769px) {
  .aboutMe {
    background-size: cover;
  }

  
}

@media (min-width: 769px) and (max-width: 1024px) {
  .aboutMe {
    background-size: contain;
    height:100vh;
  }

 
 
  .body{
    font-size: 0.7rem;
  }
  
  .image-group { 
    width: 30vw; 
    margin-left:2rem;
  }

  .icon {
    width: 2rem; 
    height: 2rem; 
  }

  .image-container {
    margin: 0 12px; 
  }

  .caption {
    white-space: wrap;
  }

  .skillButton{
    padding:4px;
  }

  .content{
    width:50vw;
    
    top:25%;
    left:40%;
  }

 

  .para{
    width: 50vw;
  }

  .arrow::before {
    width: 8px;
    height: 8px;
  }

  .aboutMe{
    margin-top: -120px;
  }
  
 


  .interest{
    width: 80vw;
  }

  .image-container:nth-child(3) {
    margin-left: 20px;
  }

  .skillUl{
    width:300px;
    gap:1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .skillUl li{
    padding: 3px;
  }

  .image-container {
    margin-right: 10px;
  }

  .image-group {
   
    margin: 30px 40px;
    width: 28vw;
    
  }

  .skills{
    margin-top: 6vh;
    
  }
}







































      
  