.projects{
    background-color:  #040404;
    
    
}


.body{
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: '100vh';
}

.border{
    border: 2px solid grey;
}

.project-row{
    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.project-col{
    margin-bottom: 4rem;
    border: none;
    display: flex;
    justify-content: center;
    
}



.card {
    border: none;
    box-shadow: 0px 4px 18px rgba(255, 255, 255, 0.5);
    /* box-shadow: 0 4px 18px rgba(206, 206, 206, 0.2); */
    width: 24rem;
    min-height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .card > .project-button {
    margin-top: 1rem;
  }
  

.project-image{
    background-color:  #040404;
    width:100%;
    height:70%;
    max-height: 248px;
}

.project-button{
   margin: auto;
    
}

.show-more-button {
   
   
    display: block;
  margin: 0 auto;
   
  }
  