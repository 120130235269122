@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montaga&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poiret+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  /* background-color: #040404; */
  background-color: transparent;
  height: 20%;
  border: none;
  padding: 30px;
  transition: box-shadow 0.4s ease, background-color 0.4s ease, padding 0.4s ease;
} 


.navbar.scrolled {
  box-shadow: 0 4px 18px rgba(206, 206, 206, 0.2); 
  background-color: #040404; 
  padding: 20px;
}

.navC.container-fluid{
  padding:0;
}


.navbar-nav {
  margin-left: auto;
 
}

.name{
  margin-left: 1.5rem;
}


.nav-link {
  /* color:rgba(255, 255, 255, 0.801); */
  color: #d4d0d0;
  font-family:  "Montaga", serif;
  font-optical-sizing: auto;
  margin-left: 10px;
  z-index: 1;
  background-color:  #040404;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}

.nav-link:last-child {
  
  margin-right: 2rem;
 
}


.nav-link:hover {
  color: gray;
}




.navbar .nav-link.active {
  font-weight: bold;
}

.navTitle{
  color: #d4d0d0;
  font-family:  "Montaga", serif;
  font-optical-sizing: auto;
}





@media (max-width: 768px) {
  .navbar-nav {
    box-shadow: 0 4px 18px rgba(206, 206, 206, 0.413);
  }
}







